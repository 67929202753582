/* Provide sufficient contrast against white background */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.app {
  background-image: linear-gradient(90deg, #fff, #efedec);
}

.componentHeader {
  background-image: linear-gradient(135deg, #d52114, #d52114 51%, #da3326 0, #da3326);
}