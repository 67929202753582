.form
{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: solid 1px #ececec;
    padding: 2vh;
    margin: 1vh;
}

.title
{
    font-weight: 600;
    font-size: 1.9em;
    line-height: 100px;
}